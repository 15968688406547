<template>
    <vx-card>
        <h2>Réservations par jour de {{ getMonth(tmonth) }}</h2>
        <br/>
        <vue-apex-charts ref="charts" type="bar" height="350" :options="columnChart.chartOptions" :series="columnChart.series"></vue-apex-charts>
    </vx-card>
</template>

<style lang="scss" scoped>

</style>

<script>
import VueApexCharts from 'vue-apexcharts'
import Member from '@/database/models/member'
import Booking from '@/database/models/booking'
import Fdate from '@/assets/utils/fdate'

export default {
    components:{
        VueApexCharts
    },
    props:['year','month'],
    data(){
    return {
      tyear  : this.year,
      tmonth : this.month,

      themeColors: [ Member.color.essai, Member.color.actif ],
      columnChart: {
        series: [{
            name: 'Essai',
            data: [0, 0, 0, 0, 0, 0]
          }, {
            name: 'Membre',
            data: [0, 0, 0, 0, 0, 0]
          }],


        chartOptions:
        {
          colors: [ Member.color.essai, Member.color.actif ],
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: 'rounded',
              columnWidth: '55%',
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },

          xaxis: {
            categories: ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
          },
          yaxis: {
            title: {
              show: false,
              text: ""
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function(val){
                return val+" Réservation(s)"
              }
            }
          }
        }
      }
    }
  },
  mounted()
  {
      this.update();
  },
  methods:{
      update()
      {
        //this.columnChart.series[0].data[2] = 55
        Booking.getTabAllMemory((list)=>
        {
            //reset
            this.columnChart.series[0].data = [0, 0, 0, 0, 0, 0];
            this.columnChart.series[1].data = [0, 0, 0, 0, 0, 0];
            
            for( var i=0; i<list.length; i++)
            {
                var buffer = list[i];
                var da = new Date(buffer.date)

                if( da.getFullYear() == this.tyear )
                if( da.getMonth() == this.tmonth )
                {
                    var day = da.getDay()-1;
                    switch (buffer.type)
                    {
                        case 'e':
                            this.columnChart.series[0].data[day]++;
                        break;
                        case 's':
                            this.columnChart.series[1].data[day]++;
                        break;
                    }
                }
            }
            this.$refs.charts.refresh();
        });
      },

      setYear( value )
      {
        this.tyear = value
      },

      setMonth( value )
      {
        this.tmonth = value
      },


      getMonth( value )
      {
          return Fdate.getTabMonth()[ value ];
      }
  }
}
</script>