<template>
	<div>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="12" vs-lg="5">
                <StatBookingAuj ref="StatBookingAuj" />
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-xs="12" vs-sm="12" vs-lg="7">
                <StatLog ref="StatLog" />
            </vs-col>
        </vs-row>
        <br/>
        <StatBookingDonut ref="statBookingDonut" />
        <br/>
        <!-- <StatBookYear ref="statBookYear" v-bind:year="select_date.getFullYear()" /> -->
        <graphEnd ref="statBookYear" v-bind:year="select_date.getFullYear()" />
        <br/>
        <BookingActivity></BookingActivity>
        <br/>
	</div>
</template>

<style lang="scss" scoped>
@media only screen and (min-width: 900px)
{
    .espace-left
    {
        padding-right:10px;
    }
    .espace-right
    {
        padding-left:10px;
    }
}
@media only screen and (max-width: 899px)
{
    .espace-left
    {
        padding-right:0px;
        margin-bottom:20px;
    }
    .espace-right
    {
        padding-left:0px;
    }
}
</style>


<script>
import StatBookYear from '@/components/stats/bookingYear.vue'
import StatBookDay from '@/components/stats/bookingDay.vue'

import StatLog from '@/components/stats/log.vue'
import StatMemberMonth from '@/components/stats/components/graphAccueil/statTransfMonth.vue'
import StatMemberYear from '@/components/stats/components/graphAccueil/statTransfYear.vue'
import graphEnd from '@/components/stats/components/graphAccueil/graphEnd.vue'
import StatBookingAuj from '@/components/stats/BookingAuj.vue'
import StatBookingDonut from '@/components/stats/bookingDonut.vue'
import BookingActivity from '@/components/stats/BookingActivity.vue'

export default {
    components:{
        StatBookYear,
        StatBookDay,
        StatLog,
        StatBookingAuj,
        StatBookingDonut,
        StatMemberMonth,
        StatMemberYear,
        graphEnd,
        BookingActivity
    },
    data(){
        return{
            select_date : new Date(),
        }
    },
    watch:{
        select_date()
        {
            this.UpdateStatsEssai()
            this.UpdateStatsBookYear();
            // this.UpdateStatsBookDay();
            this.UpdateStatTransformationMonth();
        },
    },
    methods:{
        UpdateStatsEssai()
        {
            this.$refs.statEssaiPro.setMonth( this.select_date.getMonth() );
            this.$refs.statEssaiPro.setYear( this.select_date.getFullYear() );
            this.$refs.statEssaiPro.refresh();
        },

        UpdateStatsBookYear()
        {
            this.$refs.statBookYear.setYear( this.select_date.getFullYear() );
            this.$refs.statBookYear.update();
        },

        UpdateStatsBookDay()
        {
            this.$refs.statBookDay.setMonth( this.select_date.getMonth() );
            this.$refs.statBookDay.setYear( this.select_date.getFullYear() );
            this.$refs.statBookDay.update();
        },

        UpdateStatTransformationMonth(){
            // this.$refs.statTransfMonth.setDate(this.select_date)
            // this.$refs.statTransfYear.setDate(this.select_date)
            this.$refs.taux.UpdateStatTransformationMonth(this.select_date)
        },
    }
};


//import Vue from 'vue'
//Vue.prototype.$bddRefresh.actualise();


</script>