<template>
    <div>
        <div v-if="!clicked">
            <vs-row class="my-12">
                <vs-col vs-xs="12" vs-align="center" vs-type="flex" vs-justify="center">
                    <vs-button @click="showStats()"> Afficher les statistiques d'activités </vs-button>
                </vs-col>
            </vs-row>
        </div>
        <div v-else>
            <vs-row class="mt-5">
                <vs-col vs-w="6" vs-xs="12" vs-lg="6">
                    <h2 class="text-primary mb-5">Résumé de votre activité {{year}}</h2>
                    <br />
                </vs-col>
                <vs-col style="display:flex; justify-content: end;" vs-w="6" vs-xs="12" vs-lg="6">
                    <v-select class="pt-4" v-model="year" label="Année" :options="years"  />
                    <vs-button class="btn-primary cursor m-4" type="filled" @click="activePrompt=true">Exporter</vs-button>
                </vs-col>
            </vs-row>
            
            <!-- <div>{{bookingTab.filteredBookings}}</div>
            <br/>
            <div>{{bookingTab.filteredGroups}}</div>
            <br/>
            <div>{{bookingTab.total}}</div>
            <br/>
            <div>{{bookingTab.final}}</div> -->

            <vs-prompt title="Export activité" class="export-options" @cancel="clearFields" @accept="exportToExcel" accept-text="Exporter" @close="clearFields" :active.sync="activePrompt">
                <vs-input v-model="fileName" placeholder="Nom du fichier" class="w-full" />
                <v-select v-model="selectedFormat" :options="formats" class="my-4" />
                <div class="flex">
                    <span class="mr-4">Espacement:</span>
                    <vs-switch v-model="cellAutoWidth">Espacement</vs-switch>
                </div>
            </vs-prompt>

            <vs-table :data="bookingTab.final" noDataText="Aucun résultat">
                    
                <!-- header template -->
                <!-- <template slot="header">
                    
                </template> -->
                <!-- header table -->
                <template slot="thead">
                    <vs-th v-for="h in headerVal" :key="h">{{h}}</vs-th>
                </template>

                <!--Body table-->
                <template slot-scope="{data}">
                    <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" v-bind:class="[ (tr.name == 'Total') ? 'grey-bg' : '', (tr.groupe == 'Global') ? 'font-bold' : '']">
                        <vs-td :data="data[indextr].groupe">
                        {{data[indextr].groupe}}
                        </vs-td>
                        <vs-td :data="data[indextr].name">
                        {{data[indextr].name}}
                        </vs-td>
                        <vs-td v-for="(c, key) of data[indextr].count" :data="c" :key="key">
                        {{c}}
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.grey-bg {
    background-color: lightgray;
}
</style>

<script>
import Booking from '@/database/models/booking'
import Machine from '@/database/models/machine'
import vSelect from 'vue-select'
import Utils from '@/assets/utils/utils'

export default {
    components:{
        'v-select': vSelect,
	},
    data(){
        return {
            clicked: false,

            bookingList: [],
            year : null,
            years: [],

            machines: null,

            //export
			fileName: '',
			formats:['xlsx', 'csv', 'txt'],
			cellAutoWidth: true,
			selectedFormat: 'xlsx',
			headerTitle: ['Groupe', 'Activité', 'Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
			headerVal: ['Groupe', 'Activité', 'Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
			activePrompt: false,
        }
    },
    computed: {
        bookingTab() {
            if (this.year) {
                let tab = this.bookingList.find(e => e.year === this.year)
                return tab
            } else return []
        }
    },
    methods: {
        showStats() {
            this.clicked = true
            this.update()
        },
        update() {
            Booking.getTabAllMemory((list)=> {
                for (let i of list) {
                    let d = new Date(i.createAt)
                    let y = d.getFullYear()
                    let index = this.bookingList.findIndex(book => book.year === y)

                    if (index < 0) {
                        let n = {
                            year: y,
                            bookings: [],
                            filteredBookings: [],
                            filteredGroups: [],
                            final: []
                        }
                        index = this.bookingList.push(n) - 1
                        this.years.push(y)
                    }
                    if(i.type !== "b" )
                        this.bookingList[index].bookings.push(i)
                }
                this.filterMachines()
            })
        },
        async filterMachines() {
            for (let i of this.bookingList) {
                for (let b of i.bookings ) {
                    let index = i.filteredBookings.findIndex(machine => machine.uid === b.machine)

                    if (index < 0) {
                        await Machine.getMemoryClone( b.machine, (data)=> {
                            if(data.groupe == "") data.groupe = "Crédits globaux"
                            let m = {
                                uid: data.uid,
                                name: data.name,
                                groupe: data.groupe,
                                count: [0,0,0,0,0,0,0,0,0,0,0,0]
                            }
                            index = i.filteredBookings.push(m) - 1
                        })
                    }
                    let d = new Date(b.createAt)
                    let mo = d.getMonth()
                    i.filteredBookings[index].count[mo] += 1
                }
            }
            for (let i of this.bookingList) {
                i.filteredBookings.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
                i.filteredBookings.sort((a,b) => (a.groupe.toLowerCase() > b.groupe.toLowerCase()) ? 1 : ((b.groupe.toLowerCase() > a.groupe.toLowerCase()) ? -1 : 0))
            }
            this.filterGroups()
        },
        filterGroups() {
            for (let i of this.bookingList) {
                for (let b of i.filteredBookings ) {
                    let index = i.filteredGroups.findIndex(group => group.groupe === b.groupe)

                    if (index < 0) {
                        let g = {
                            name: "Total",
                            groupe: b.groupe,
                            count: [0,0,0,0,0,0,0,0,0,0,0,0]
                        }
                        index = i.filteredGroups.push(g) - 1
                    }
                    for (let mo=0; mo<12; mo++) {
                        i.filteredGroups[index].count[mo] += b.count[mo]
                    }
                }
            }

            this.getTotal()
        },
        getTotal() {
            for (let i of this.bookingList) {
                i.total = {
                    name: "Total",
                    groupe: "Global",
                    count: [0,0,0,0,0,0,0,0,0,0,0,0]
                }
                for (let b of i.filteredGroups ) {
                    for (let mo=0; mo<12; mo++) {
                        i.total.count[mo] += b.count[mo]
                    }
                }
            }
            this.buildTab()
        },
        buildTab() {

            for (let b of this.bookingList) {
                b.final = []
                
                for (let i = 0; i < b.filteredBookings.length; i++) {
                    b.final.push(b.filteredBookings[i])
                    
                    if (i == b.filteredBookings.length -1 ) {
                        let group = b.filteredGroups.find(group => group.groupe === b.filteredBookings[i].groupe)
                        b.final.push(group)
                        b.final.push(b.total)
                    } else {
                        if (b.filteredBookings[i].groupe !== b.filteredBookings[i+1].groupe) {
                            let group = b.filteredGroups.find(group => group.groupe === b.filteredBookings[i].groupe)
                            b.final.push(group)
                        }
                    }
                }
            }
            this.showTab()
        },
        showTab() {
            this.bookingList.sort((a,b) => b.year - a.year)
            this.years.sort().reverse()
            this.year = this.bookingList[0].year
        },

        //----------------------------------------------------
		//export excel
		//----------------------------------------------------
		clearFields ()
		{
			this.filename = ''
			this.cellAutoWidth = true
			this.selectedFormat = 'xlsx'
		},
		exportToExcel ()
		{
			let root = this;

			import('@/assets/utils/Export2Excel')
			.then(excel => {
				//get data selected ou all member
				let list = root.bookingTab.final

				//Parse correction
				let final_export = []
				list = JSON.parse(JSON.stringify(list))
				for( var i in list )
				{
					let buffer = JSON.parse(JSON.stringify(list[i]));
                    let buffer2 = {
                        Groupe: buffer.groupe,
                        Activité: buffer.name,
                        Jan: buffer.count[0],
                        Fév: buffer.count[1],
                        Mar: buffer.count[2],
                        Avr: buffer.count[3],
                        Mai: buffer.count[4],
                        Juin: buffer.count[5],
                        Juil: buffer.count[6],
                        Août: buffer.count[7],
                        Sept: buffer.count[8],
                        Oct: buffer.count[9],
                        Nov: buffer.count[10],
                        Déc: buffer.count[11],
                    }
					final_export.push( buffer2 );
				}

				//prépare data
				const data = this.formatJson(root.headerVal, final_export);

				//export
				excel.export_json_to_excel(
				{
					header: root.headerTitle,
					data,
					filename : root.fileName,
					autoWidth: root.cellAutoWidth,
					bookType : root.selectedFormat
				});
				root.clearFields();
			});
		},
		formatJson (filterVal, jsonData)
		{
			let data = jsonData.map(v => filterVal.map(function(j) 
			{
				//Correction de champs ICI
				//if(j == 'uid')
				//	v[j] = undefined;
				return v[j];
			}));

			//BUG! enlever les duplications !!
			filterVal = Utils.removeDuplicate(filterVal);
			//Fin
			return data;
		},
    }
}


</script>