<template>
  <vs-row  vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12">
    <vs-col   vs-align="center"  vs-xs="12" vs-sm="12" vs-lg="12" class="py-3">
      <vx-card >
        <!-- <template v-if="this.oncharge==false"> -->
          <vs-row>
            <vs-col vs-w="11">
            <h2> Taux de transformation par mois </h2>
          </vs-col>
            <vs-col vs-w="1">
              <vs-icon icon="info" size="small" bg="primary"  color="white" round  @click="getInfo=true;"></vs-icon>
            </vs-col>
            <vs-popup  title="Informations Complémentaires" :active.sync="getInfo" >
              <h3 class="pop " > Informations</h3>
              <br/>
                <p class="popT">Appuyer sur le bouton "Mise à jour" pour charger les données.
                </p>
              
              <vs-divider/>
              <h3 class="pop " > Méthode</h3>
              <br/>
                <p class="popT"> Les transformations de séances d'essai sont comptabilisées le mois de la signature du contrat.</p>
              
              <vs-divider/>

              <h3 class="pop "> Exemple</h3>
              <br/>
              <p class="popT"> Un client effectue une séance d'essai le 15 janvier.<br/>
              Il achète des séances en mars.<br/>
              La transformation sera appliquée en mars.</p>

            </vs-popup>
          </vs-row>

          <br/>
          <br/>
            <vue-apex-charts type="bar" height="350"  :options="columnChart.chartOptions" :series="columnChart.series"></vue-apex-charts>
          <br/>
          <br/>
          <!-- <vs-button @click="refresh()"> Mettre à jour  </vs-button> -->
        <!-- </template> -->
        <!-- <template v-else>
          <wait/>
        </template>  -->
      </vx-card>
   </vs-col>
  </vs-row>
</template>

<script>

import wait from './waiting'

import Member from '@/database/models/member'
import Item from '@/database/models/item'
import autoRefresh from '@/assets/utils/autoRefresh'
import moment from 'moment'
import VxCard from '@/components/vx-card/VxCard.vue'
import VueApexCharts from 'vue-apexcharts'
export default {
  props:{
    datee:"",
  },
  components:{
    Member,
    autoRefresh,
    Item,
    VxCard,
    VueApexCharts,
    wait
    
  },
  data() {
    return {
      monthNames : ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin",
          "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Decembre"],
      month:"",
      nbrNewMbr :0,
      nbrEss : 0,
      nbrEssMbrN :0,
      nbrEssMbrB :0,

      listMember :{},
      listNewMemberB :[],

      called:false,


      oncharge:false,

      date:this.datee,
      listItem:{},
      getInfo:false,

      tablepourcentage:[],
      listNewMember:[],
      listMemberEssai:[],

      default:0,
      moyenne:0,
      yearAbo:[],


      //  pour le graphe : il manque à en selectionné un d'offoice 

      
      columnChart: {
        series: [
          ],
        chartOptions: {
          colors: ['#28C76F','#7367F0' , '#EA5455', '#FF9F43', '#1E1E1E'],
          plotOptions: {
            bar: {
              horizontal: false,
              endingShape: 'rounded',
              columnWidth: '55%',
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },

          xaxis: {
            categories: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jui', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
          yaxis: {
            title: {
              text: 'Nouveaux Clients '
            }
          },
          fill: {
            opacity: 1

          },
          tooltip: {
            y: {
              formatter: function(val) {
                return val + " nouveaux clients "
              }
            }
          }
        }
      }
    }      
  },
  mounted:function(){
    // autoRefresh.SetCallback(async()=>
    //     {
          
    //       this.listMember = await Member.AwaitGetAllMemory();
    //       this.listItem = await Item.AwaitGetAllMemory();
          
    //       this.getAnnuelNumber();
        
           
           
    //     })
    //     autoRefresh.refresh();
    
  },
  methods:{
    refresh(){
      autoRefresh.SetCallback(async()=>
        {
          this.reinitialize()
          this.columnChart.series=[]
          this.listMember = await Member.AwaitGetAllMemory();
          this.listItem = await Item.AwaitGetAllMemory();
          this.called = true;
           await this.getAnnuelNumber();
        
           
           
        })
        autoRefresh.refresh();
    
    },
    async setDate(date){
      autoRefresh.SetCallback(async()=>
        {
          
          if( this.date.getFullYear()!= date.getFullYear() && this.called==true){
            this.date=date;
            this.refresh()
            // this.columnChart.series=[];
            // this.getAnnuelNumber();
          }
          else if( this.called==false){
            this.date=date;
            this.refresh()
          }
          else{
              this.date=date;

          }
        
           
           
        })
        autoRefresh.refresh();
      
      
    
      
    },
    reinitialize(){
      this.nbrEss=0;
      this.nbrNewMbr=0;
      this.nbrEssMbrN=0;
      this.nbrEssMbrB=0;
      this.getEssai=false;
      this.selected=[];
      this.listNewMemberB=[];
      this.listMemberEssai=[];
      this.listNewMember=[];
      
    },

   
      async getAnnuelNumber(){
          this.oncharge=true;
          // this.changedcharge(true)
          //this.$parent.$emit('updateCharge', this.oncharge)
          // let endMonth = this.date.getMonth();
          let list =[];
          let listB=[];
          
          for (let i=0; i<12; i++){
            var number=0;
            var numberB=0;
             for (let j in this.listMember){
               let c = this.listMember[j].uid;
               let aux = await this.getNumberMonth(i, c);
                if ( aux !=null && aux !=-1)
                    number = number + aux;
                else if ( aux==-1)
                    numberB = numberB+1;
                
    


               
             }
            
             list.push(number)
             listB.push(numberB)
          }
          // for (let i=endMonth+1; i<12; i++){
          //     list.push(0)
          //     listB.push(0)
          // }
            this.yearAbo=list;
            
            let auxi = {
                name:'Transformations < 30 jours ',
                data:this.yearAbo,
            }
            let auxiB = {
                name:'Transformations > 30 jours ',
                data:listB,
            }
            this.columnChart.series.push(auxi,auxiB)
            // this.oncharge=false;
            this.changedcharge(false);
            // this.$emit('updateCharge', this.oncharge)
            
          
          

      },
      changedcharge(val){
          this.oncharge=val;
          this.$parent.$emit('updateCharge', val)
      }, 
      async getNumberMonth(mois, uid){

        let firstday = new Date(this.date.getFullYear(), mois, 1);
        let deb = moment(firstday,"").format("YYYY-MM-DD")
        let lastDay = new Date(this.date.getFullYear(), mois+1, 0);
        let fin = moment(lastDay,"").format("YYYY-MM-DD")
        var factFirst={};
        var d = moment(new Date(),"").format("YYYY-MM-DD").toString();
        
        var changed = false; 
        
          // for(let i in this.listItem){
          //   let aux = this.listItem[i];

           

          //   // on recupere les factures de chaque individus 
          //   if ( aux.member == uid && aux.credit >0){
          //       let create = moment(aux.createAt,"").format("YYYY-MM-DD")
          //       if ( moment(create.toString()).isBefore(d) || moment(create.toString()).isSame(d)  ){
          //           changed=true;
          //           d= create.toString();
          //           factFirst=aux;
          //       }

          //   }
           
          // }

           let first = await Item.AwaitGetAllMemoryKey(uid)
          if (first){
          
            for ( let i in first){
              let j = new Date(first[i].createAt).getDate()
              let m = new Date(first[i].createAt).getMonth()+1
              let y = new Date(first[i].createAt).getFullYear()
              
              let createa = y+"-"+m+"-"+j
              let create = moment(createa,"YYYY-MM-DD").format("YYYY-MM-DD")
                  // on regarde si la date de la facture correspont à la premiere facture 
                  if ( moment(create.toString()).isBefore(d) || moment(create.toString()).isSame(d)  ){
                      
                      changed=true;
                      d= create.toString();
                      factFirst=first[i];
                    
                  }
                  
                  
                  
            }
          }

            let datee = moment(this.listMember[uid].createAt,"").format("YYYY-MM-DD");
            let creat = this.listMember[uid].createAt;
          if ( moment(d).isBetween(deb.toString(), fin.toString()) || moment(d).isSame(deb.toString()) || moment(d).isSame(fin.toString()) && changed ==true){
              
              // date de la premiere facture superieur à celle de création du membre 
              if( moment(d).isAfter(datee.toString()) && changed==true ){

                  // si il a été crée avant ce mois ci 
                  if ( moment(datee.toString()).isBefore(deb.toString()) && changed==true){

                     
                      return -1; 
                  }
                  // si il a été cree ce mois ci !! pas  le meme jour 
                  else if(moment(datee.toString()).isBetween(deb.toString(), fin.toString()) || moment(datee.toString()).isSame(deb.toString()) || moment(datee.toString()).isSame(fin.toString()) && changed==true ){
                      
                      return 1; 
                  }
                  
              }
              // si la date de la premiere facture est la meme
              else if (moment(d).isSame(datee.toString()) && changed==true){
                let h = moment( creat, "").format("HH:mm"); // creation membre 
                let hF = moment(factFirst.createAt,"").format("HH:mm")  // creation facture 
                let hs = h.split(":");
                let hfs = hF.split(":");

                if (parseInt(hs[0])>=parseInt(hfs[0])){
                  //si l'heure est superieur ou la meme , on regarde les minutes 
                  if ( parseInt(hs[1])>parseInt(hfs[1])){
                    
                    return 1;
                  }
                }


              }
              else{
                return null;
              }

              /////////////////

          
          

          }

      },
      
  }
 
}
</script>

<style lang="scss" scoped>

.pop{
   color:#5b3cc4;
  }

.under{
  text-decoration: underline;
}

@media only screen and (min-width: 900px)
{
    .card_adjust{
        margin-left:10px;
    }
}
@media only screen and (max-width: 899px)
{
    .card_adjust{
        margin-left:0px;
        margin-top:20px;
    }
}


</style>